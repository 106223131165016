import fetchData from "helpers/fetchApi";
import useAsync from "helpers/hooks/useAsync";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

export default function NotificationCard({data}) {
  console.log(data);
  const [cookies, setCookie, removeCookie] = useCookies()
  const openNotif = useAsync();
  const [openedNotifications, setOpenedNotifications] = useState([]);
  
  const openNotification = (id) => {
    openNotif.run(fetchData({url: "/notifications/read/" + id, token: cookies.token}))
  }

  const storeOpenedNotif = (data) => {
    setOpenedNotifications([]);
    if(data != undefined && data != null){
      if(data.status == true){
        setOpenedNotifications([...openedNotifications, parseInt(data.data)]);
      }
    }
  }

  useEffect(() => {
    storeOpenedNotif(openNotif.data);
  }, [openNotif.data])

  return (
    <div className="pt-6 pb-10 px-10 border border-gray-900">
      <div>
        <h1 className="text-2xl font-semibold mb-1">Notifikasi</h1>
        <p className="text-md">
          Anda dapat dapat melihat notifikasi pada halaman ini
        </p>
        <hr className="w-full border-gray-900 my-6" />
      </div>
      <div>
        {data.map((item, key) => {
          console.log(key)
          console.log(item)
          return (
            <div
              className={["w-full mb-6 border border-gray-800 p-4", (item.is_read == 0 && openedNotifications.includes(item.id) == false) ? "bg-gray-200" : ""].join(" ")}
              onClick={() => openNotification(item.id)}
              key={key}
            >
              <div className="grid grid-cols-12 h-full">
                <div className="col-span-12 md:col-span-9 flex justify-start h-full">
                 <div className="grid grid-cols-12">
                    <div className="md:col-span-3 col-span-12 md:h-32 sm:h-24 h-44 flex md:justify-start justify-center">
                      <img className="h-full mr-4" src={process.env.REACT_APP_BACKEND_WEB + "/" + item.image} alt="" />
                    </div>
                    <div className="md:col-span-9 col-span-12 md:ml-4 md:mt-1 mt-2">
                      <h5 className="font-semibold">{item.title}</h5>
                      <p className="">{item.description}</p>
                    </div>
                 </div>
                </div>
                <div className="col-span-12 md:col-span-3 flex items-center md:justify-end">
                  {
                    (item.sales_id != null) ?
                    <Link to={"/transaction/detail/" + item.sales_id} className="py-2 px-4 md:mt-0 mt-4 md:w-auto w-full text-center bg-teal-700 text-white">Lihat</Link> : ''
                  }
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
