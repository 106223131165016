import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { initFlowbite } from "flowbite";
import { Link, useLocation, useNavigate } from "react-router-dom";
import fetchApi from "helpers/fetchApi";
import useAsync from "helpers/hooks/useAsync";
import { useCookies } from "react-cookie";
import AnimateHeight from "react-animate-height";
import Footer from "./Footer";
import Otp from "./Otp";
import Register from "./Register";
import StorePassword from "./StorePassword";
import Login from "./Login";
import useWindowDimensions from "helpers/hooks/useWindowDimensions";
import { HashLink } from "react-router-hash-link";
import LoadingScreen from "./LoadingScreen";
import getUserId from "helpers/hooks/getUserId";

export default function Header(custom) {
  const [cookies, setCookies, removeCookies] = useCookies();
  const location = useLocation();
  const navigate = useNavigate()
  const token = cookies.token;

  useEffect(() => {
    initFlowbite();
    return () => {};
  }, [custom.refreshCart]);

  const [loading, setLoading] = useState(false);
  const [loadProgress, setLoadProgress] = useState(5);

  useEffect(() => {
    if(loadProgress  >= 100){
      setLoading(false);
    }
  }, [loadProgress])

  const [keyword, setKeyword] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    const path = "/products?keyword=" + keyword;

    window.location = path;
  };

  const handleChangeKeyword = (value) => {
    if (value == undefined) {
      setKeyword(null);
    } else {
      setKeyword(value);
    }
  };

  useEffect(() => {
    if (custom.keyword) {
      setKeyword(custom.keyword);
    }
  }, [custom.keyword]);

  // logout
  const [refreshCart, setRefreshCart] = useState(false)
  const handleLogOut = () =>{
    removeCookies('username')
    removeCookies('full_name')
    removeCookies('user_type')
    removeCookies('token')
    setTotalCart(0);
    setToggleMobileNav(0);
    navigate('/')
  }

  const verifAdminRequest = useAsync();

  const handleToAdmin = () => {
    setLoading(true);
    verifAdminRequest.run(
      fetchApi({ url: "/auth/check-admin", body:{token: cookies.token}, method: "POST", token: cookies.token })
    );
  }

  useEffect(() => {
    console.log('verif admin')
    console.log(verifAdminRequest)
    if (verifAdminRequest.data != undefined && verifAdminRequest.data != null && cookies?.token) {
      if (verifAdminRequest.data.status == true) {
        window.location.href = process.env.REACT_APP_BACKEND_WEB+`/admin-login?token=${encodeURIComponent(cookies.token)}`;
      }else{
        setLoadProgress(100)
      }
    }else{
    }
  }, [verifAdminRequest.data, cookies]);

  // fetc total chart
  const cartTotalRequest = useAsync();
  const [totalCart, setTotalCart] = useState(0);

  useEffect(() => {
    if(cookies?.token){
      cartTotalRequest.run(
        fetchApi({ url: "/cart-total/" + cookies.username, token: cookies.token })
      );
    }
  }, [cartTotalRequest.run, custom.refreshCart, refreshCart, cookies]);

  useEffect(() => {
    console.log('cart total')
    console.log(cartTotalRequest)
    if (cartTotalRequest.data != undefined && cartTotalRequest.data != null) {
      if (cartTotalRequest.data.status == true) {
        setTotalCart(cartTotalRequest.data.data);
      }
    }
  }, [cartTotalRequest.data]);

  const [toggleMobileNav, setToggleMobileNav] = useState(0);

  const handleOpenMobileNav = () => {
    setToggleMobileNav("auto");
  };
  const handleCloseMobileNav = () => {
    setToggleMobileNav(0);
  };

  // scroll to id (mobile)
  const handleScrollTo = (id) => {
    setToggleMobileNav(0)
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth'
    });
  }

  const { height, width } = useWindowDimensions();

  // Auth 
  const [isRegister, setIsRegister] = useState(false);
  const [userData, setUserData] = useState(null);

  const handleRegister = (value) => {
    console.log(value);
    setIsRegister(value);
    
    if(value == false){
      setOpenVerif(false)
    }
  };

  const [userId, setUserId] = useState(null);
  const [openVerif, setOpenVerif] = useState(false);
  const [openStorePassword, setOpenStorePassword] = useState(false);

  const handleGetUserId = async() =>{
    const getId = await getUserId(cookies.username, cookies.token);
    if(getId.result == true){
      setUserId(getId.id);
    }
  }

  const handleOpenVerif = (value, data) => {
    setUserData(data);
    if(value == true && data != null && data != undefined){
      setOpenVerif(false);
      setOpenStorePassword(true)
    }else if(value == true){
      setOpenVerif(false);
      setOpenStorePassword(false)
    }else{
      setOpenVerif(true)
      setOpenStorePassword(false)
    }
  };

  const handleOpenFormPassword = (value) => {
    setOpenStorePassword(value)
  }

  const [subtitle, setSubtitle] = useState("Masukkan kode OTP yang anda terima pada nomor");

  const handleChangePhone = (value) => {
    setSubtitle("Masukkan kode OTP yang anda terima pada nomor " + value);
  }

  const [toggleMobileAuth, setToggleMobileAuth] = useState(0);

  const handleCloseLogin = () => {
    console.log('close login')
    setToggleMobileAuth(0)
    setRefreshCart(!refreshCart)
  }


  // Notifications
  const notifications = useAsync();
  const [countNotifications, setCountNotifications] = useState(0);
  const getNotifications = (userId, token) => {
    notifications.run(fetchApi({ url: "/notifications/" + userId, token: token}));
  }

  const storeNotifications = (data) => {
    setCountNotifications(0);
    if(data != undefined && data != null){
      if(data.status == true && data.data.length > 0){
        let filterNotif = data.data.filter((item) => item.is_read == 0);
        setCountNotifications(filterNotif.length);
      }
    }
  }

  useEffect(() => {
    if(userId != null && cookies?.token){
      getNotifications(userId, cookies.token)

      // const interval = setInterval(() => getNotifications(userId, cookies.token), 4000)
      // return () => {
      //   clearInterval(interval);
      // }
    }
  }, [userId, cookies])

  useEffect(() => {
    storeNotifications(notifications.data)
  }, [notifications.data])

  useEffect(() => {
    if(cookies?.token){
      handleGetUserId()
    }
  }, [cookies])
  
  useEffect(() =>{
    console.log('count notif')
    console.log(countNotifications)
  }, [countNotifications])

  const authPage = 
    <>
      <div
        className="flex items-center bg-white px-7 py-8 w-full xl:w-3/4"
        style={{ minHeight: "420px" }}
      >
        {isRegister == true ? (
          openVerif == true ?
          <Otp title="Daftar" subTitle={subtitle} toRegister={handleOpenVerif} toLogin={handleRegister} backButton={true} data={userData}/> :
          (openStorePassword == false) ? <Register toLogin={handleRegister} changePhone={handleChangePhone} toVerif={handleOpenVerif}/> : <StorePassword data={userData} toLogin={handleRegister} openFormPassword={handleOpenFormPassword}/>
        ) : (
          <Login toRegister={handleRegister} close={handleCloseLogin} />
        )}
      </div>
    </>

  const profileDropdown = <>
    <div class="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
        <button type="button" class="flex text-sm bg-gray-800 rounded-full md:me-3 focus:ring-4 focus:ring-gray-300 relative" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
          <span class="sr-only">Open user menu</span>
          <img class="w-8 h-8 rounded-full" src="/images/profile/user.jpeg" alt="user photo"/>
          <div className="absolute left-5 top-5">
            {
              (countNotifications) ?
              <span className="w-4 h-4 rounded-full bg-red-700 text-white flex items-center justify-center text-center text-sm">{countNotifications}</span> : ''
            }
          </div>
        </button>
          
        <div class="z-50 hidden my-4 text-base list-none bg-gray-50 divide-y divide-gray-100 shadow-lg" id="user-dropdown">
          <div class="px-4 py-3">
            <span class="block text-sm text-gray-900 ">Hi, {cookies.full_name}</span>
          </div>
          <ul class="py-2" aria-labelledby="user-menu-button">
            {
              cookies.user_type == 1 ? 
              <li>
                <button onClick={handleToAdmin} class="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex w-full items-center">
                  <Icon icon="dashicons:admin-home" width={20} className="mr-2"/>
                  <span>Go to Admin Page</span>
                </button>
              </li> : ''
            }
            <li>
              <Link to="/profile" class="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex w-full items-center">
                <Icon icon="iconamoon:profile-fill" width={20} className="mr-2"/>
                <span>Profile</span>
              </Link>
            </li>
            <li>
              <Link to="/notification" class="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex w-full items-center justify-between">
                <div className="flex items-center">
                  <Icon icon="iconamoon:notification-fill"  width={20} className="mr-2"/>
                  <span>Notifikasi</span>
                </div>
                <div>
                  {
                    (countNotifications) ?
                    <span className="w-5 h-5 text-sm rounded-full bg-red-700 text-white flex items-center justify-center text-center">{countNotifications}</span> : ''
                  }
                </div>
              </Link>
            </li>
            <li>
              <Link to="/transaction" class="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex w-full items-center">
                <Icon icon="icon-park-solid:transaction-order"  width={20} className="mr-2"/>
                <span>History Transaksi</span>
              </Link>
            </li>
            <li>
              <button type="button" onClick={handleLogOut} class="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex w-full items-center">
                <Icon icon="fluent:sign-out-20-filled" width={20} className="mr-2"/>
                <span>Sign out</span>
              </button>
            </li>
          </ul>
        </div>
        <button data-collapse-toggle="navbar-user" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-user" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
          </svg>
      </button>
    </div>
  </>

  return (
    <>
      {
        (loading == true) ? <LoadingScreen progress={loadProgress}/> : ''
      }

      <div className="xl:px-40 lg:px-20 px-5 fixed bg-white w-full z-30">
        <header className={[custom.position, "w-full z-20 overflow-y-hidden"].join(" ")}>
          <div className="py-5">
            <div className="flex items-center justify-between">
              <div className="xl:w-56 sm:w-24 items-center flex">
                <Link to="/">
                  <img
                    src="/images/content/logo_company.png"
                    alt="Az Zahra"
                    style={{ width: "100px", maxWidth: "none" }}
                  />
                </Link>
              </div>
              <div className="w-auto">
                <ul
                  className="fixed bg-white inset-0 flex flex-col invisible items-center justify-center opacity-0 md:visible md:flex-row md:bg-transparent md:relative md:opacity-100 md:flex md:items-center"
                  id="menu"
                >
                  <li className="mx-3 py-6 md:py-0 font-medium whitespace-nowrap">
                    {
                      (location.pathname == '/') ? 
                      <a
                        href="#top-products"
                        className={["hover:underline", "text-black"].join(" ")}
                      >
                        Best Product
                      </a>
                      : 
                      <HashLink
                        to="/#top-products"
                        className={["hover:underline", "text-black"].join(" ")}
                      >
                        Best Product
                      </HashLink>
                    }
                  </li>
                  <li className="mx-3 py-6 md:py-0 font-medium whitespace-nowrap">
                    {
                      (location.pathname == '/') ?
                      <a
                        href="#services"
                        className={["hover:underline", "text-black"].join(" ")}
                      >
                        Services
                      </a>
                      :
                      <HashLink
                        to='/#services'
                        className={["hover:underline", "text-black"].join(" ")}
                      >
                        Services
                      </HashLink>
                    }
                  </li>
                  <li className="mx-3 py-6 md:py-0 font-medium whitespace-nowrap">
                    {
                      (location.pathname == '/') ?
                      <a
                        href="#category"
                        className={[
                          "hover:underline, flex items-center",
                          "text-black",
                        ].join(" ")}
                      >
                        <span>Category</span>
                        {/* <span>
                          <Icon icon="fe:arrow-down" />
                        </span> */}
                      </a> :
                      <HashLink
                        to="/#category"
                        className={[
                          "hover:underline, flex items-center",
                          "text-black",
                        ].join(" ")}
                      >
                        <span>Category</span>
                        {/* <span>
                          <Icon icon="fe:arrow-down" />
                        </span> */}
                      </HashLink>
                    }
                  </li>
                </ul>
              </div>
              <div className="w-auto xl:pl-24 lg:pl-8 xl:pr-6 pr-0   md:block hidden">
                <form onSubmit={handleSubmit}>
                  <label
                    for="keyword-search"
                    className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                  >
                    Search
                  </label>
                  <div className="relative xl:w-52 lg:w-36 ms-auto">
                    <input
                      type="search"
                      id="keyword-search"
                      onChange={(e) => handleChangeKeyword(e.target.value)}
                      value={keyword}
                      className="block w-full rounded-2xl p-3 pl-4 text-sm focus:outline-none border-none"
                      style={{
                        backgroundColor: "#F5F5F5",
                        color: "#999999",
                      }}
                      placeholder="Cari"
                    />
                    <button
                      type="submit"
                      className=" absolute right-2.5 bottom-1/3  focus:outline-none font-medium rounded-lg text-sm "
                      style={{
                        color: "#999999",
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      <Icon icon="tabler:search" width={20} />
                    </button>
                  </div>
                </form>
              </div>

              <div className="w-auto">
                <ul className="items-center flex">
                  {
                    (cookies.token) ? 
                    <li className="xl:ml-6 ml-1">
                      <Link
                        to="/cart"
                        className={[
                          "flex items-center justify-center w-8 h-8 relative",
                          "text-black",
                        ].join(" ")}
                      >
                        <span className="absolute bg-red-600 text-white text-xs w-4 h-4 text-center rounded-full bottom-0 right-0">
                          {totalCart}
                        </span>
                        <Icon icon="mdi:cart" width="200" />
                      </Link>
                    </li> : ''
                  }
                  <li className="ml-6 block md:hidden">
                    <button
                      id="menu-toggler"
                      className="relative flex z-50 items-center justify-center w-8 h-8 text-black md:text-white focus:outline-none me-2"
                      onClick={() =>
                        setToggleMobileNav(toggleMobileNav === 0 ? "auto" : 0)
                      }
                    >
                      <Icon icon="ri:menu-3-fill" width={55} />
                      <div className="absolute left-5 top-5">
                        {
                          (countNotifications) ?
                          <span className="w-4 h-4 rounded-full bg-red-700 text-white flex items-center justify-center text-center text-sm">{countNotifications}</span> : ''
                        }
                      </div>
                    </button>
                  </li>
                  <li className="xl:ml-6 ml-3 xl:pl-6 py-6 md:py-0 font-medium whitespace-nowrap md:block hidden">
                    {
                      (token != undefined && token != null) ?
                        profileDropdown : 
                        <HashLink
                          to="/#hero"
                          className={["hover:underline", "text-black"].join(" ")}
                        >
                          Masuk
                        </HashLink>
                    }
                    
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </div>

      <AnimateHeight
        duration={500}
        height={toggleMobileNav}
        easing="ease"
        className="fixed overflow-hidden z-40 md:hidden"
      >
        <div className="block w-screen h-screen bg-white px-5 py-5 relative">
          <div className="flex justify-between items-center mb-6">
            <div className="w-1/2">
              <form onSubmit={handleSubmit}>
                <label
                  for="keyword-search"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                >
                  Search
                </label>
                <div className="relative xl:w-52 lg:w-36 ms-auto">
                  <input
                    type="search"
                    id="keyword-search"
                    onChange={(e) => handleChangeKeyword(e.target.value)}
                    value={keyword}
                    className="block w-full rounded-2xl p-3 pl-4 text-sm focus:outline-none border-none"
                    style={{
                      backgroundColor: "#F5F5F5",
                      color: "#999999",
                    }}
                    placeholder="Cari"
                  />
                  <button
                    type="submit"
                    className=" absolute right-2.5 bottom-1/3  focus:outline-none font-medium rounded-lg text-sm "
                    style={{
                      color: "#999999",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <Icon icon="tabler:search" width={20} />
                  </button>
                </div>
              </form>
            </div>
            <div className="flex justify-between items-center flex-row">
              {
                (cookies.token) ? 
                <Link
                  to="/cart"
                  className={[
                    "flex items-center justify-center w-8 h-8 relative mr-6",
                    "text-black",
                  ].join(" ")}
                >
                  <span className="absolute bg-red-600 text-white text-xs w-4 h-4 text-center rounded-full bottom-0 right-0">
                    {totalCart}
                  </span>
                  <Icon icon="mdi:cart" width="200" />
                </Link>
                : ''
              }
              <button
                onClick={() =>
                  setToggleMobileNav(toggleMobileNav === 0 ? "auto" : 0)
                }
              >
                <Icon icon="charm:cross" height={30} />
              </button>
            </div>
          </div>
          {
            (cookies.token != undefined && cookies.token != null) ? '' :
            <button className="bg-gray-200 hover:bg-gray-300 w-full py-3.5 font-medium mb-4" onClick={() =>{
                setToggleMobileAuth("auto")
                setToggleMobileNav(0)
              }}>
              Masuk
            </button>
          }
          <div>
            <ul className="list-none text-lg">
              <li className="pb-3 w-full md:py-0 font-medium whitespace-nowrap">
                {
                  (location.pathname == '/') ? 
                  <a
                    // href="#top-products"
                    onClick={(e) => handleScrollTo("hero")}
                    className={["hover:underline", "text-black"].join(" ")}
                  >
                    Home
                  </a>
                  :
                  <HashLink
                    to="/#hero"
                    onClick={handleCloseMobileNav}
                    className={["hover:underline", "text-black"].join(" ")}
                  >
                    Home
                  </HashLink>
                }
              </li>
              <li className="pb-3 w-full md:py-0 font-medium whitespace-nowrap">
                {
                  (location.pathname == '/') ? 
                  <a
                    // href="#top-products"
                    onClick={(e) => handleScrollTo("top-products")}
                    className={["hover:underline", "text-black"].join(" ")}
                  >
                    Best Product
                  </a>
                  :
                  <HashLink
                    to="/#top-products"
                    onClick={handleCloseMobileNav}
                    className={["hover:underline", "text-black"].join(" ")}
                  >
                    Best Product
                  </HashLink>
                }
              </li>
              <li className="pb-3 w-full md:py-0 font-medium whitespace-nowrap">
                {
                  (location.pathname == '/') ? 
                  <a
                    // href="#services"
                    onClick={(e) => handleScrollTo("services")}
                    className={["hover:underline", "text-black"].join(" ")}
                  >
                    Services
                  </a>
                  :
                  <HashLink
                    to="/#services"
                    onClick={handleCloseMobileNav}
                    className={["hover:underline", "text-black"].join(" ")}
                  >
                    Services
                  </HashLink>
                }
              </li>
              <li className="pb-3 w-full md:py-0 font-medium whitespace-nowrap">
                {
                  (location.pathname == '/') ? 
                  <a
                    onClick={(e) => handleScrollTo("category")}
                    className={["hover:underline", "text-black"].join(" ")}
                  >
                    <span>Category</span>
                  </a>
                  :
                  <HashLink
                    to="/#category"
                    onClick={handleCloseMobileNav}
                    className={["hover:underline", "text-black"].join(" ")}
                  >
                    Category
                  </HashLink>
                }
              </li>
              {
                (cookies.token != undefined && cookies.token != null) ?
                <>
                  <li className="pb-3 w-full md:py-0 font-medium whitespace-nowrap">
                    <Link to="/profile" className={["hover:underline", "text-black"].join(" ")}>
                      Profile
                    </Link>
                  </li>
                  <li className="pb-3 w-full md:py-0 font-medium whitespace-nowrap">
                    <Link to="/notification" className={["hover:underline", "text-black"].join(" ")}>
                      <div className=" flex items-center w-full justify-between">
                        <span className="inline-block">Notifikasi</span>
                        <div>
                          {
                            (countNotifications) ?
                            <span className="w-6 h-6 rounded-full bg-red-700 text-white flex items-center justify-center text-center">{countNotifications}</span> : ''
                          }
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="pb-3 w-full md:py-0 font-medium whitespace-nowrap">
                    <Link to="/transaction" className={["hover:underline", "text-black"].join(" ")}>
                      History Transaksi
                    </Link>
                  </li>
                </> : ''
              }
            </ul>
          </div>
          {
            (cookies.token != undefined && cookies.token != null) ?
            <>
              {
                ( cookies.user_type == 1 ) ? 
                <button className="bg-gray-200 hover:bg-gray-300 w-full py-3.5 font-medium mt-4" onClick={() => handleToAdmin()}>
                  Go to Admin Page
                </button> : ''
              }
              <button className="bg-gray-200 hover:bg-gray-300 w-full py-3.5 font-medium mt-3" onClick={() => handleLogOut()}>
                Logout
              </button> 
            </>
            : ''
          }
        </div>
      </AnimateHeight>

      {/* Auth Page */}
      {
        (width < 1280) ? 
        <AnimateHeight 
          duration={500}
          height={toggleMobileAuth}
          easing="ease"
          className="fixed overflow-hidden z-50 md:hidden">
          <div className="block w-screen h-screen bg-white px-5 py-5 relative">
            <div className="flex justify-between items-center mb-6">
              <div className="w-1/2">
                <h2 className="text-xl font-semibold">Autentikasi</h2>
              </div>
              <button
                onClick={() =>
                  setToggleMobileAuth(0)
                }
              >
                <Icon icon="charm:cross" height={30} />
              </button>
            </div>
            {authPage}
          </div>
        </AnimateHeight> : ''
      }
    </>
  );
}
