import React from 'react'
import Swal from 'sweetalert2';

const basicAlert = (type = 'info', title = "Alert", text = "") =>{
    const swalCustom = Swal.mixin({
      customClass: {
        popup:'rounded-sm',
        confirmButton: "px-3 py-2 rounded-sm w-full bg-teal-400 text-white",
        cancelButton: "px-3 py-2 rounded-sm w-full bg-red-600 text-white"
      },
      buttonsStyling: false
    });

    return swalCustom.fire({
        icon: type,
        title: title,
        text: text,
      });
}

export default basicAlert;