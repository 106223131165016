import React, { useEffect, useState } from "react";
import Footer from "parts/Footer";
import Header from "parts/Header";
import Sitemap from "parts/Sitemap";
import NotificationSection from "parts/Notification/NotificationSection";
import useAsync from "helpers/hooks/useAsync";
import getUserId from "helpers/hooks/getUserId";
import { useCookies } from "react-cookie";
import fetchData from "helpers/fetchApi";
import LoadingScreen from "parts/LoadingScreen";

const notificationData = [
    {
        id: 1,
        image: "/images/products/among.png",
        title: "Pesanan Anda telah selesai dengan kode pesanan 123",
    },
    {
        id: 2,
        image: "/images/products/among.png",
        title: "Pesanan Anda telah selesai dengan kode pesanan 123",
    },
    {
        id: 3,
        image: "/images/products/among.png",
        title: "Pesanan Anda telah selesai dengan kode pesanan 123",
    },
    {
        id: 4,
        image: "/images/products/among.png",
        title: "Pesanan Anda telah selesai dengan kode pesanan 123",
    },
    {
        id: 5,
        image: "/images/products/among.png",
        title: "Pesanan Anda telah selesai dengan kode pesanan 123",
    },
]

export default function Notification() {
  // fetch product
  const notifications = useAsync();

  const [userId, setUserId] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies()
  const [loading, setLoading] = useState(true);
  const [loadProgress, setLoadProgress] = useState(5);
  const [notificationsData, setNotificationsData] = useState([]);

  useEffect(() => {
    if(loadProgress  >= 100){
      setLoading(false);
    }
  }, [loadProgress])

  const handleGetUserId = async() =>{
    const getId = await getUserId(cookies.username, cookies.token);
    if(getId.result == true){
      setUserId(getId.id);
    }
  }

  const getNotifications = (userId, token) => {
    notifications.run(fetchData({ url: "/notifications/" + userId, token: token}));
  }

  const storeNotifications = (data) => {
    setNotificationsData([]);
    if(data != undefined && data != null){
      if(data.status == true && data.data.length > 0){
        setLoadProgress(100);
        setNotificationsData(data.data);
      }else{
        setLoadProgress(100);
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0,0);
    handleGetUserId()
  }, [])

  useEffect(() => {
    if(userId != null){
      getNotifications(userId, cookies.token)
    }
  }, [userId, cookies])

  useEffect(() => {
    storeNotifications(notifications.data)
  }, [notifications.data])


  useEffect(() =>{
    console.log('notif')
    console.log(notificationsData)
  }, [notificationsData])

  return (
    <>
      { 
        (loading == true) ? <LoadingScreen progress={loadProgress}/> : ''
      }
      <Header />
      <NotificationSection data={notificationsData} />
      <Sitemap />
      <Footer />
    </>
  );
}
