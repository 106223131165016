import React, { useState } from "react";
import { Link } from "react-router-dom";

function SiteMapLinks({ isActive, setActive, children, title }) {
  return (
    <div className="px-4 w-full md:w-2/12 mb-4 md:mb-0 accordion">
      <h5 className="text-lg font-semibold mb-2 relative">
        {title}
        <button
          onClick={() => setActive(isActive)}
          className={[
            "absolute block md:hidden right-0 transform -translate-y-1/2 focus:outline-none transition duration-200 top-1/2",
            isActive ? "rotate-0" : "rotate-180",
          ].join(" ")}
        >
          <svg
            width="20"
            height="9"
            viewBox="0 0 20 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.197257 0.403676C0.526597 -0.0396672 1.15298 -0.132085 1.59632 0.197256L9.75 6.25427L17.9037 0.197256C18.347 -0.132085 18.9734 -0.0396672 19.3027 0.403676C19.6321 0.847019 19.5397 1.4734 19.0963 1.80274L10.3463 8.30274C9.99227 8.56575 9.50773 8.56575 9.15368 8.30274L0.403676 1.80274C-0.0396667 1.4734 -0.132084 0.847019 0.197257 0.403676Z"
              fill="black"
            />
          </svg>
        </button>
      </h5>
      <ul
        className={[
          "md:h-auto md:visible md:opacity-100 overflow-hidden transition duration-200",
          isActive ? "h-0 invisible opacity-0" : "opacity-100",
        ].join(" ")}
      >
        {children}
      </ul>
    </div>
  );
}

function SingleSiteMapLinks({ title }) {
  return (
    <div className="px-4 w-full md:w-2/12 mb-4 md:mb-0 accordion">
      <Link to="/">
        <h5 className="text-lg font-semibold mb-2 relative">{title}</h5>
      </Link>
    </div>
  );
}

export default function Sitemap() {
  const [active, setActive] = React.useState(null);
  return (
    <section className="sitemap xl:px-40 lg:px-20 px-5">
      <div className="border-gray-200 py-12 mt-3">
        <aside className="container">
          <div className="flex justify-start">
            <div className="px-4 w-full md:w-3/12 mb-4 md:mb-0">
              <Link to="/">
                <img
                  src="/images/content/logo_company.png"
                  alt="Az Zahra"
                  className="md:w-auto w-36"
                />
              </Link>
              <h4 className="text-md mb-8">
                Persewaan Baju Among
                <br />
                Tamu & Seserahan
              </h4>
            </div>
            <div>
              <h2 className="xl:text-2xl text-xl font-semibold xl:mb-8 mb-3">Accepted Payment</h2>
              <div className="flex align-middle xl:mb-0 mb-5">
                <img
                  className="mr-8"
                  src="/images/content/method1.png"
                  alt="Luxspace | Fulfill your house with beautiful furniture"
                />
                {/* <img
                  src="/images/content/method2.png"
                  alt="Luxspace | Fulfill your house with beautiful furniture"
                /> */}
              </div>
            </div>
            {/* <SingleSiteMapLinks title="About Us" />
            <SingleSiteMapLinks title="Help" />
            <SingleSiteMapLinks title="Category" />
            <SingleSiteMapLinks title="Contact Us" /> */}
          </div>
        </aside>
      </div>
    </section>
  );
}
