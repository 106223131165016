import OrderButton from "parts/OrderButton";
import OrderList from "parts/OrderList";
import React, { useEffect, useState } from "react";

export default function PaymentInfo({ products, onProses, buttonParams, salesData, paymentType = null }) {
  const handlePayment = (value) => {
    onProses(value);
  };

  const [disabledBtn, setDisabedBtn] = useState(false);

  useEffect(() => {
    if(typeof buttonParams.disabled != undefined){
      setDisabedBtn(buttonParams.disabled)
    }
  }, [buttonParams])
  

  return (
    <>
      <OrderList products={products} salesData={salesData} paymentType={paymentType} />
      <OrderButton buttonParams={buttonParams} onPayment={handlePayment} disabled={disabledBtn}/>
    </>
  );
}
