import React, { useEffect } from "react";
import { initFlowbite } from "flowbite";
import { Button } from "flowbite-react";
import useAsync from "helpers/hooks/useAsync";
import fetchApi from "helpers/fetchApi";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import AnimateHeight from "react-animate-height";
import { Icon } from "@iconify/react";
import useWindowDimensions from "helpers/hooks/useWindowDimensions";

// const categories = [
//   {
//     id: 1,
//     name: "Bridesmaid",
//   },
//   {
//     id: 2,
//     name: "Preweding",
//   },
//   {
//     id: 3,
//     name: "Busana Keluarga",
//   },
//   {
//     id: 4,
//     name: "Buku Tamu",
//   },
//   {
//     id: 5,
//     name: "Busana Pria",
//   },
//   {
//     id: 6,
//     name: "Busana Wanita",
//   },
// ];

// const sizes = [
//   {
//     id: 1,
//     name: "XL",
//   },
//   {
//     id: 2,
//     name: "L",
//   },
//   {
//     id: 3,
//     name: "M",
//   },
//   {
//     id: 4,
//     name: "S",
//   },
// ];

const rangePrice = [
  {
    id: "price_1",
    value: 100000,
    name: "< Rp100.000",
  },
  {
    id: "price_2",
    value: 150000,
    name: "Rp 100.000 - Rp 150.000",
  },
  {
    id: "price_3",
    value: 200000,
    name: "Rp 150.000 - Rp 200.000",
  },
  {
    id: "price_4",
    value: 250000,
    name: "> Rp 200.000",
  },
];

export default function Filter({getFilter, toggleOpenMenu, handleCloseMobileFilter, category}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const keyword = searchParams.get('keyword');
  const [cookies, setCookies, removeCookies] = useCookies();
  const navigate = useNavigate();
  const token = cookies.token;

  useEffect(() => {
    initFlowbite();
    return () => {};
  }, []);


  // fetch categories
  const categories = useAsync();

  useEffect(() => {
    categories.run(fetchApi({ url: "/kategori/catalog", token: token }));
  }, [categories.run]);


  // fetch categories
  const sizes = useAsync();

  useEffect(() => {
    sizes.run(fetchApi({ url: "/size", token: token }));
  }, [sizes.run]);

  const handleFilter = () => {
    const category = document.getElementsByName("category");
    const min_price = document.getElementById("min_price").value;
    const max_price = document.getElementById("max_price").value;

    let categoryValue;
    for (var i = 0, length = category.length; i < length; i++) {
      if (category[i].checked) {
        categoryValue = category[i].value;
        break;
      }
    }
    
    if(typeof getFilter == 'function'){
      console.log("search");
      getFilter(categoryValue, [min_price, max_price])
    }
  }

  const handleClear = () => {
    document.getElementsByName("category").value = "";
    document.getElementsByName("max_price").value = "";
    document.getElementsByName("min_price").value = "";
    window.location.href = '/products'
  }

  const handleCloseMenu = () => {
    if(typeof handleCloseMobileFilter == 'function'){
      handleCloseMobileFilter(toggleOpenMenu === 0 ? "auto" : 0)
    }
  }

  const { height, width } = useWindowDimensions();

  const filterSection = 
  <>
    <div className="relative pt-8 md:pt-0">
        <h2 className="h2">Hasil Pencarian : {(keyword != undefined && keyword != null && keyword != "") ? keyword : ''}</h2>
        <div className="relative mt-3">
          {/* Accordion */}
          <div id="accordion-open" data-accordion="open">
            {/* Kategori */}
            <h2 id="category-collapse-heading z-30">
              <button
                type="button"
                className="flex items-center justify-between w-full p-1 font-medium text-left !bg-white !text-gray-900"
                data-accordion-target="#category-collapse-body"
                aria-expanded="true"
                aria-controls="category-collapse-body"
              >
                <span className="text-gray-900">Kategori</span>
                <svg
                  data-accordion-icon
                  className="w-3 h-3 rotate-180 shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>
            <div
              id="category-collapse-body"
              className="hidden"
              aria-labelledby="category-collapse-heading"
            >
              <div className="p-3">
                <ul className="w-full text-sm font-medium text-gray-900">
                  {
                  (categories.data != undefined && categories.data != null) ? (categories.data.status == true && categories.data.data.length > 0) ? categories.data.data.map(function (item, index) {
                    return (
                      <li className="w-full" key={index}>
                        <div className="flex items-center">
                          <input
                            id={
                              item.category_id +
                              "_" +
                              item.category_name.toLocaleLowerCase().split(" ").join("_")
                            }
                            type="radio"
                            value={item.category_id}
                            name="category"
                            className="hidden peer"
                            defaultChecked={(item.category_id == category) ? true : false}
                          />
                          <label
                            for={
                              item.category_id +
                              "_" +
                              item.category_name.toLocaleLowerCase().split(" ").join("_")
                            }
                            className="w-full mb-1 ml-2 text-sm font-medium cursor-pointer text-gray-400 peer-checked:text-gray-900 hover:text-gray-900"
                          >
                            {item.category_name}
                          </label>
                        </div>
                      </li>
                    );
                  }) : '' : ''
                }
                </ul>
              </div>
            </div>

            {/* Harga */}
            <h2 id="price-collapse-heading">
              <button
                type="button"
                className="flex items-center justify-between w-full p-1 font-medium text-left !bg-white !text-gray-900"
                data-accordion-target="#price-collapse-body"
                aria-expanded="true"
                aria-controls="price-collapse-body"
              >
                <span>Harga</span>
                <svg
                  data-accordion-icon
                  className="w-3 h-3 rotate-180 shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>
            <div
              id="price-collapse-body"
              className="hidden"
              aria-labelledby="price-collapse-heading"
            >
              <div className="p-3">
                <div className="flex justify-between items-center">
                  <input
                    type="text"
                    id="min_price"
                    class="text-gray-800 w-full border border-gray-400 font-light"
                    placeholder="Min"
                    required
                  />

                  <h3 className="text-lg my-0 mx-3">to</h3>
                  <input
                    type="text"
                    id="max_price"
                    class="text-gray-800 w-full border border-gray-400 font-light"
                    placeholder="Max"
                    required
                  />

                </div>
                {/* <ul className="w-full text-sm font-medium text-gray-900">
                  {rangePrice.map(function (item) {
                    return (
                      <li className="w-full">
                        <div className="flex items-center">
                          <input
                            id={item.id}
                            type="radio"
                            value={item.id}
                            name="price"
                            className="hidden peer"
                          />
                          <label
                            for={item.id}
                            className="w-full mb-1 ml-2 text-sm font-medium cursor-pointer text-gray-400 peer-checked:text-gray-900 hover:text-gray-900"
                          >
                            {item.name}
                          </label>
                        </div>
                      </li>
                    );
                  })}
                </ul> */}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <Button
            size="lg"
            className="w-full py-1 sm:py-2 rounded-sm"
            style={{
              backgroundColor: "#56A9B7",
              "hover": {
                backgroundColor: "white",
                color: "#56A9B7",
              },
            }}
            onClick={handleFilter}
          >
            <span className="text-lg sm:text-xl">Cari</span>
          </Button>
          <Button
            size="lg"
            className="w-full py-1 sm:py-2 rounded-sm border-gray-400 text-gray-400 mt-3"
            style={{
              fontSize: "50px",
              "hover": {
                backgroundColor: "gray",
                color: "black",
              },
            }}
            onClick={handleClear}
          >
            <span className="text-lg sm:text-xl">Atur Ulang</span>
          </Button>
          <Button
            size="lg"
            className="w-full py-1 sm:py-2 rounded-sm text-center bg-gray-500 text-white mt-3 md:hidden"
            style={{
              fontSize: "50px",
              "hover": {
                backgroundColor: "gray",
                color: "black",
              },
            }}
            onClick={handleCloseMenu}
          >
            <span className="text-lg sm:text-xl text-center">Close</span>
          </Button>
        </div>
      </div>
  </>

  return (
    <>
    {
      (width >= 768) ? filterSection : 
      <AnimateHeight
        duration={500}
        height={toggleOpenMenu}
        easing="ease"
        className="fixed overflow-hidden z-30 top-0 left-0"
      >
        <div className="md:hidden block w-screen h-screen bg-white px-5 py-5 relative">
          <div className="flex justify-between items-center mb-6">
            <div className="w-1/2">
              <h4 className="text-xl font-semibold">Filter</h4>
            </div>
            <button
              onClick={handleCloseMenu}
            >
              <Icon icon="charm:cross" height={30} />
            </button>
          </div>
            {filterSection}
        </div>
      </AnimateHeight>
    } 
    </>
  );
}
