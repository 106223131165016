import { Icon } from "@iconify/react";
import { Button } from "flowbite-react";
import useAsync from "helpers/hooks/useAsync";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import fetchApi from "helpers/fetchApi";
import LoadingScreen from "./LoadingScreen";

export default function Login({ toRegister, redirectRegister = true, close, refreshCart, toVerif }) {
  const [loading, setLoading] = useState(false);
  const [loadProgress, setLoadProgress] = useState(5);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleRegister = () => {
    if (typeof toRegister == "function") {
      toRegister(true);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    if (showPassword == false) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  const [cookies, setCookie, removeCookie] = useCookies();
  const [erroLogin, setErrorLogin] = useState(null);

  const loginRequest = useAsync();


  const handleLogin = async (e) => {
    e.preventDefault()
    setErrorLogin(null);

    const data = {
      username: username,
      password: password
    }
    setLoadProgress(5);
    await loginRequest.run(fetchApi({ url: "/auth/login", method: "POST", body: data }));

    if (loginRequest.error) {
      setErrorLogin(loginRequest.error);
      return
    }
  }

  const API_URL = process.env.REACT_APP_BACKEND_API;
  const verifToken = async (token) => {

    const response = await fetch(API_URL + '/auth/me', {
      method: 'POST',
      mode: "cors",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
    });
    const data = await response.json();

    if (data != undefined && data != null) {
      removeCookie('username')
      removeCookie('full_name')
      removeCookie('user_type')
      setCookie('username', data.username, { sameSite: "lax" })
      setCookie('full_name', data.full_name, { sameSite: "lax" })
      setCookie('user_type', data.user_type, { sameSite: "lax" })
    }
  }

  const handleCookiesLogin = async (token) => {
    if (token != undefined) {
      removeCookie('token')
      setCookie('token', token, { sameSite: "lax" })
      await verifToken(token);

      if (typeof refreshCart == 'function') {
        refreshCart(true);
      }

      if (typeof close == 'function') {
        close()
      }
    }
  }

  const otpRequest = useAsync();
  const handleOtpRequest = async (id) => {
    const data = {
      user_id: id
    }

    await otpRequest.run(fetchApi({ url: "/generate-otp", method: "POST", body: data }));

    if (otpRequest.data != undefined && otpRequest.data != null) {
      if (otpRequest.data.status == true) {
        const data = {
          user_id: otpRequest.data.data.user_id,
          exp: otpRequest.data.data.expire_at
        }

        console.log(data);
        if (typeof toVerif == 'function') {
          handleRegister();
          toVerif(false, data)
        }
      }
    }
  }

  useEffect(() => {
    if (loginRequest.isIdle == false) {
      if (loginRequest.isLoading == true) {
        setLoading(true);
      } else {
        setLoadProgress(100);
      }
    }
  }, [loginRequest])


  useEffect(() => {
    if (loginRequest.data != undefined && loginRequest.data != null) {
      if (loginRequest.data.error) {
        setErrorLogin(loginRequest.data.error)
        setLoadProgress(100);
      } else {
        setLoadProgress(100);
      }

      if (loginRequest.data.status == false) {
        handleOtpRequest(loginRequest.data.user_id);
      } else {
        handleCookiesLogin(loginRequest.data.access_token);
      }
    }
  }, [loginRequest.data])

  useEffect(() => {
    if (loadProgress >= 100) {
      setLoading(false);
    }
  }, [loadProgress])



  return (
    <>
      {
        (loading == true) ? <LoadingScreen progress={loadProgress} /> : ''
      }
      <div className="w-full">
        <form onSubmit={handleLogin}>
          <h1 className="text-center text-2xl font-medium mb-5">Masuk</h1>
          {
            (erroLogin != null) ?
              <>
                <div class="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
                  <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                  </svg>
                  <span class="sr-only">Error</span>
                  <div>
                    <span class="font-medium">Error!</span> {erroLogin}.
                  </div>
                </div>
              </> : ''
          }
          <div>
            <input
              type="text"
              id="username"
              class="text-gray-800 w-full border border-gray-400 font-light mb-3"
              placeholder="Usename"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <div className="relative">
              <input
                type={showPassword == false ? "password" : "text"}
                id="password"
                class="text-gray-800 w-full border border-gray-400 font-light"
                placeholder="Kata Sandi"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                <Icon
                  icon="mdi:eye-outline"
                  className={showPassword == false ? "block" : "hidden"}
                  onClick={handleShowPassword}
                  width="23"
                  color="#999999"
                />
                <Icon
                  icon="mdi:eye-off-outline"
                  className={showPassword == false ? "hidden" : "show"}
                  onClick={handleShowPassword}
                  width="23"
                  color="#999999"
                />
              </div>
            </div>
            <Button
              type="submit"
              size="sm"
              className="w-full py-2 rounded-sm mt-5 mb-2"
              style={{
                backgroundColor: "#56A9B7",
                hover: {
                  backgroundColor: "white",
                  color: "#56A9B7",
                },
              }}
            >
              <span className="text-base font-light">Masuk</span>
            </Button>
            <Link>
              <p className="text-blue-800 font-normal" style={{ fontSize: "13px" }}>
                Lupa Password
              </p>
            </Link>
          </div>
          {
            (redirectRegister == true) ?
              <div className="mt-5">
                <p className="text-center text-gray-500" style={{ fontSize: "13px" }}>
                  Belum Memiliki Akun?{" "}
                  <span
                    className="text-blue-800 cursor-pointer"
                    onClick={handleRegister}
                  >
                    Daftar
                  </span>
                </p>
              </div> : ''
          }
        </form>
      </div>
    </>
  );
}
