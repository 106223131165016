import BasicAlert from "components/Alert/basicAlert";
import { Button } from "flowbite-react";
import fetchData from "helpers/fetchApi";
import formatDate from "helpers/hooks/formatDate";
import useAsync from "helpers/hooks/useAsync";
import LoadingScreen from "parts/LoadingScreen";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";

export default function ProfileCard() {
  const [cookies, setCookies, removeCookies] = useCookies();
  const userRequest = useAsync();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadProgress, setLoadProgress] = useState(5);

  // State form data
  const [formData, setFormData] = useState({
    username: '',
    full_name: '',
    email: '',
    phone: '',
    gender: '',
    date_of_birth: ''
  });

  const [openForm, setOpenForm] = useState(false);
  const handleToggleForm = () => {
    setOpenForm(!openForm)
  }

  useEffect(() => {
    if(loadProgress  >= 100){
      setLoading(false);
    }
  }, [loadProgress])

  useEffect(() => {
    console.log('testing');
    const token = cookies.token;
    userRequest.run(fetchData({ url: "/profile?username=" + cookies.username, token: token}));
  }, [openForm])

  useEffect(() => {
    if(userRequest.data != undefined && userRequest.data != null){
      if(userRequest.data.status == true){
        setLoadProgress(100);
        setUserData(userRequest.data.data);
        setFormData({
          username: userRequest.data.data?.username,
          full_name: userRequest.data.data?.full_name,
          email: userRequest.data.data?.email,
          phone: userRequest.data.data?.phone,
          gender: userRequest.data.data?.gender,
          date_of_birth: userRequest.data.data?.date_of_birth,
        })
      }else{
        setLoadProgress(100);
      }
    }
  }, [userRequest.data])

  // Hit on Change input
  const handleChangeForm = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value  
    });
  } 

  // Hit API to update profile data
  const handleUpdateProfile = async () => {
    const updateProfile = await fetchData({ url: "/account-management/" + userData.username, token: cookies.token, method: 'PUT', body: formData});

    if(updateProfile?.result == false){
      BasicAlert('error', 'Oops!', 'Ada kesalahan ketika update profile, silahkan hubungi Administrator !');
    }else{
      setOpenForm(false);
      BasicAlert('success', 'Success!', 'Berhasil mengubah data profile.');
    }
  }

  return (
    <>
      {
        (loading == true) ? 
          <>
            <LoadingScreen progress={loadProgress}/> 
            <div className="h-64"></div>
          </>
        : 
        <form>
          <div className="pt-6 md:pb-10 pb-8 md:px-10 px-0 md:border md:border-gray-900">
            <div className="md:block hidden">
              <h1 className="text-2xl font-semibold mb-1">Profil Saya</h1>
              <p className="text-md">
                Anda dapat mengelola dan mengamankan akun anda
              </p>
              <hr className="w-full border-gray-900 my-6" />
            </div>
            <div>
              <div className="grid md:grid-cols-5 grid-cols-6" style={{ gridAutoRows: "auto" }}>
                <div className="md:col-span-1 col-span-3 md:mb-1 mb-3">
                  <h5 className="font-semibold text-md text-gray-400 mb-3">Nama</h5>
                </div>
                <div className="md:col-span-4 col-span-3 md:mb-1 mb-3">
                  {
                    (openForm == true) ? 
                    <input type="text" name="full_name" className="w-full mb-3" defaultValue={userData.full_name} onChange={(e) => handleChangeForm(e)}/>
                    :
                    <h5 className="font-normal md:text-left text-right text-md mb-3">{userData.full_name}</h5>
                  }
                </div>
                <div className="md:col-span-1 col-span-3 md:mb-1 mb-3">
                  <h5 className="font-semibold text-md text-gray-400 mb-3">
                    Username
                  </h5>
                </div>
                <div className="md:col-span-4 col-span-3 md:mb-1 mb-3">
                    <h5 className="font-normal md:text-left text-right text-md mb-3">{userData.username}</h5>
                </div>
                <div className="md:col-span-1 col-span-3 md:mb-1 mb-3">
                  <h5 className="font-semibold text-md text-gray-400 mb-3">Email</h5>
                </div>
                <div className="md:col-span-4 col-span-3 md:mb-1 mb-3">
                  {
                    (openForm == true) ?
                    <input type="email" name="email"  className="w-full mb-3" defaultValue={userData.email} readOnly={true} onChange={(e) => handleChangeForm(e)}/>
                    :
                    <h5 className="font-normal md:text-left text-right text-md mb-3">{userData.email}</h5>
                  }
                </div>
                <div className="md:col-span-1 col-span-3 md:mb-1 mb-3">
                  <h5 className="font-semibold text-md text-gray-400 mb-3">
                    No. Telp
                  </h5>
                </div>
                <div className="md:col-span-4 col-span-3 md:mb-1 mb-3">
                  {
                    (openForm == true) ?
                    <input type="tel" name="phone" className="w-full mb-3" defaultValue={userData.phone} onChange={(e) => handleChangeForm(e)}/>
                    :
                    <h5 className="font-normal md:text-left text-right text-md mb-3">{userData.phone}</h5>
                  }
                </div>
                <div className="md:col-span-1 col-span-3 md:mb-1 mb-3">
                  <h5 className="font-semibold text-md text-gray-400 mb-3">
                    Jenis Kelamin
                  </h5>
                </div>
                <div className="md:col-span-4 col-span-3 md:mb-1 mb-3">
                  {
                    (openForm) ?
                    <>
                      <select name="gender" className="w-full mb-3" onChange={(e) => handleChangeForm(e)}>
                        <option value="0" selected={(userData.gender == 0)}>Laki-laki</option>
                        <option value="1" selected={(userData.gender == 1)}>Perempuan</option>
                      </select>
                    </>
                    :
                    <h5 className="font-normal md:text-left text-right text-md mb-3">{(userData.gender != null) ? (userData.gender == 0) ? 'Laki-laki' : 'Perempuan' : ''}</h5>
                  }
                </div>
                <div className="md:col-span-1 col-span-3 md:mb-1 mb-3">
                  <h5 className="font-semibold text-md text-gray-400 mb-3">
                    Tanggal Lahir
                  </h5>
                </div>
                <div className="md:col-span-4 col-span-3 md:mb-1 mb-3">
                  {
                    (openForm == true) ?
                    <input type="date" name="date_of_birth" className="w-full mb-3" defaultValue={userData.date_of_birth != null ? new Date(userData.date_of_birth).toISOString().split('T')[0] : ''} onChange={(e) => handleChangeForm(e)}/>
                    :
                    <h5 className="font-normal md:text-left text-right text-md mb-3">{(userData.date_of_birth != null) ? formatDate(userData.date_of_birth) : ''}</h5>
                  }
                </div>
                {
                  (openForm == true) ? 
                  <>
                    <div className="md:col-span-1 col-span-6 md:pr-3 md:mt-2 mt-4">
                      <Button
                        size="sm"
                        className="px-2 md:py-2 py-3 rounded-sm  w-full border-2 border-gray-600 mb-2"
                        style={{
                          backgroundColor: "white",
                          color: "#000",
                          hover: {
                            backgroundColor: "white",
                            color: "#000",
                          },
                        }}
                        onClick={() => handleToggleForm()}
                      >
                        <span className="text-md font-normal">Cancel</span>
                      </Button>
                    </div>
                    <div className="md:col-span-1 col-span-6 mt-2">
                      <Button
                        size="sm"
                        className="px-2 md:py-2 py-3 rounded-sm  w-full"
                        style={{
                          backgroundColor: "#56A9B7",
                          hover: {
                            backgroundColor: "white",
                            color: "#56A9B7",
                          },
                        }}
                        onClick={() => handleUpdateProfile()}
                      >
                        <span className="text-md font-normal">Simpan</span>
                      </Button>
                    </div>
                  </>:
                  <>
                    <div className="md:col-span-1 col-span-6 mt-4">
                      <Button
                        size="sm"
                        className="px-2 md:py-1 py-3 rounded-sm w-full"
                        style={{
                          backgroundColor: "#56A9B7",
                          hover: {
                            backgroundColor: "white",
                            color: "#56A9B7",
                          },
                        }}
                        onClick={() => handleToggleForm()}
                      >
                        <span className="text-md font-normal">Ubah</span>
                      </Button>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </form>
      }
    </>
  );
}
