import { Icon } from "@iconify/react";
import fetchData from "helpers/fetchApi";
import formatDate from "helpers/hooks/formatDate";
import useAsync from "helpers/hooks/useAsync";
import LoadingScreen from "parts/LoadingScreen";
import OrderList from "parts/OrderList";
import formatToIDR from "parts/formatToIDR";
import React, { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const passStyle = {
  backgroundColor: "#E4FBFF",
  color: "#56A9B7",
};
const onGoingStyle = {
  backgroundColor: "#56A9B7",
  color: "#fff",
};
const becomeStyle = {
  backgroundColor: "#999999",
  color: "#f5f5f5",
};

const cancelStyle = {
  backgroundColor: "#ffdede",
  color: "#fa0000",
}

const warningStyle = {
  backgroundColor: "#ffb06b",
  color: "#d15a3d",
}

export default function TransactionCard() {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [openMobileDetail, setOpenMobileDetail] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadProgress, setLoadProgress] = useState(5);

  useEffect(() => {
    if(loadProgress  >= 100){
      setLoading(false);
    }
  }, [loadProgress])

  const handleOpenMobileDetail = () => {
    if(openMobileDetail  == 0){
      setOpenMobileDetail('auto');
    }else{
      setOpenMobileDetail(0);
    }
  }

  const nullParams = [null, undefined, "", "null", 0]
  const params = useParams();
  const id = params.idc;

  const salesRequest = useAsync();
  const [salesData, setSalesData] = useState(null)

  useEffect(() => {
    salesRequest.run(fetchData({url: '/sales/' + id, token: cookies.token}))
  }, [salesRequest.run])

  useEffect(() => {
    if(!nullParams.includes(salesRequest.data)){
      if(salesRequest.data.data){
        setLoadProgress(100);
        setSalesData(salesRequest.data.data);
      }
    }
  }, [salesRequest.data])

  const statusRequest = useAsync();
  const [statusList, setStatusList] = useState([]);
  useEffect(() => {
   statusRequest.run(fetchData({url: '/get-status-transaction', token:cookies.token}))
  }, [statusRequest.run])

  useEffect(() => {
    if(statusRequest.data != undefined && statusRequest.data != null){
      if(statusRequest.data.data.length > 0){
        setStatusList(statusRequest.data.data);
      }
    }
  }, [statusRequest.data])

  const [paidDownPayment, setPaidDownPayment] = useState(false);
  const [paidFullPayment, setPaidFullPayment] = useState(false);

  useEffect(() =>{
    console.log('sales')
    console.log(salesData)
    if(salesData?.payment){
      if(salesData?.payment.length > 0){
        const dpPayment = salesData.payment.find((item) => (item.payment_type == 0 || item.payment_type == null) && item.verif_payment == 1);
       
        if(dpPayment != undefined){
          setPaidDownPayment(true)
        }else{
          setPaidDownPayment(false);
        }

        const fullPayment = salesData.payment.find((item) => (item.payment_type == 1) && item.verif_payment == 1);

        console.log('pay');
        console.log(fullPayment);

        if(fullPayment != undefined) {
          setPaidFullPayment(true);
        }else{
          setPaidFullPayment(false);
        }
      }
    }
  }, [salesData])

  const [hoverPayStatus, setHoverPayStatus] = useState(false);
    

  const detailInfo = 
    <>
      {
        (salesData != null) ?
        <div className="md:hidden block fixed w-full z-30 bottom-0" style={{left:'1px'}}>
            <AnimateHeight duration={500} height={openMobileDetail}>
              <div className="max-h-80 overflow-y-auto bg-white px-5 py-2 pb-6" style={{ boxShadow:'0px 0px 7px 0px rgba(0,0,0,0.6)' }}>
                <div className="flex justify-between my-3">
                  <h3 className="text-lg font-semibold">
                    Rincian Pesanan Penyewaan
                  </h3>
                  <button
                    onClick={() => handleOpenMobileDetail()}
                  >
                    <Icon icon="charm:cross" height={25} />
                  </button>
                </div>
                <div>
                  <OrderList products={salesData.detail} />
                </div>
              </div>
            </AnimateHeight>
            <div className="border-2 bg-white border-black w-full h-14 m-0">
              <button className="w-full h-14 flex justify-center items-center text-sm font-semibold" onClick={handleOpenMobileDetail}>
                  <div className="flex justify-between w-full items-center px-5">
                    <div className="flex items-center justify-between w-full h-14">
                      <h3 className="text-sm font-semibold text-center">Total Harga</h3>
                      <h3 className="text-sm font-semibold text-center">Rp. 600.0000</h3>
                    </div>
                    <Icon icon="mingcute:up-line" width={28} className="ml-2"/>
                  </div>
              </button>
            </div>
        </div> : ''
      }
      
    </>

    const skeleton = <>
      <div className="md:p-5 py-5 px-3 border border-gray-400 rounded-sm animate-pulse">
              <div className="grid grid-cols-4 w-full mb-6" style={{ gridAutoRows:'auto' }}>
                <div className="md:col-span-1 col-span-2">
                  <div className="md:border-r md:border-gray-500">
                    <h5 className="mb-2 md:text-md w-20 h-3 bg-gray-600 rounded-full"></h5>
                    <p className="mb-1 w-16 h-3 bg-gray-600 rounded-full"></p>
                    <p className="mb-1 w-14 h-3 bg-gray-600 rounded-full"></p>
                  </div>
                </div>
                <div className="md:col-span-3 col-span-2">
                  <div className="flex items-end flex-col">
                    <div className="mb-6 w-20 h-3 bg-gray-600 rounded-full"></div>
                    <div className="flex justify-between">
                      <div className="w-20 h-3 bg-gray-600 rounded-full ml-3"></div>
                      <div className="w-20 h-3 bg-gray-600 rounded-full ml-3"></div>
                      <div className="w-20 h-3 bg-gray-600 rounded-full ml-3"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="grid grid-cols-6 items-end hover:bg-gray-100 md:px-0 px-2 mb-5" style={{ gridAutoRows:'auto' }}>
                  <div className="md:col-span-5 col-span-6">
                    <div className="grid grid-cols-6 justify-between md:flex-row w-full relative">
                      <div className="md:col-span-1 col-span-2 overflow-hidden max-h-48 flex justify-center items-center">
                        <div className="bg-gray-400 h-44 w-full flex justify-center items-center">
                          <svg class="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                              <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z"/>
                              <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"/>
                          </svg>
                        </div>
                      </div>
                      <div className="md:col-span-5 col-span-4">
                        <div className="flex flex-col justify-between px-4 leading-normal md:w-3/4 w-full">
                          <div>
                            <h5 className="mb-3 w-56 h-3 bg-gray-600 rounded-full"></h5>
                            <p className="mb-4 w-44 h-3 bg-gray-600 rounded-full"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:col-span-1 md:h-full col-span-6  ">
                    <div className="flex items-center justify-end h-full">
                      <h5 className="mb-2 w-56 h-3 bg-gray-600 rounded-full"></h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-400 pt-4 md:block hidden">
                <div className="grid grid-cols-5" style={{ gridAutoRows:'auto' }}>
                  <div className="col-span-3">
                    <div className="h-full flex items-end">
                      <p className="text-md">
                        Barang disewakan dengan durasi normal 3 x 24 jam proses sewa{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div className="flex justify-end items-center">
                      <h5 className="w-56 h-3 bg-gray-600 rounded-full"></h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </>


  return (
    <>
      {
        (loading == true) ? <LoadingScreen progress={loadProgress}/> : ''
      }
     <div className="pt-24 xl:px-40 lg:px-20 px-6 min-h-screen">
      {
        (salesRequest.isLoading) ? skeleton : 
        (salesData != null && statusList.length > 0) ?
        <>
          <div className="md:p-5 py-5 px-3 border border-gray-400 rounded-sm">
            <div className="grid grid-cols-4 w-full mb-6" style={{ gridAutoRows:'auto' }}>
              <div className="md:col-span-1 col-span-4">
                <div className="md:border-r md:border-gray-500">
                  <h5 className="font-semibold text-gray-900 mb-2 xl:text-lg md:text-md">
                    Alamat Penyewa
                  </h5>
                  <p className="mb-1 md:text-md text-sm">
                    {salesData.user.full_name} - {salesData.user.phone}
                  </p>
                  <p className="mb-1 text-gray-400 md:text-md text-sm">{salesData.address}</p>
                </div>
              </div>
              <div className="md:col-span-3 col-span-4">
                <div>
                  <h5 className="font-semibold text-gray-900 mb-2 text-md md:hidden mt-3">
                    Transaksi
                  </h5>
                  <div className="grid grid-cols-4">
                    <div className="md:text-right md:mb-6 mb-2 xl:text-lg md:text-md text-sm font-medium md:col-span-4 col-span-2">
                      <div className="inline-block">
                        {formatDate(salesData.sales_date)}
                      </div>
                    </div>
                    <div className="grid-cols-12 md:hidden mb-3 md:col-span-4 col-span-2">
                      {statusList.map((status) => {
                        const salesStatus = parseInt(salesData.status);
                        let statusStyle;

                        if(status.id_transaction_status == 1 || status.id_transaction_status == 6){
                          statusStyle = cancelStyle;
                        }else{
                          statusStyle = onGoingStyle;
                        }

                        if (status.id_transaction_status == salesStatus) {
                          return (
                            <div
                              className="xl:col-span-2 col-span-3 text-xs font-medium mr-2 px-3 py-1 text-center"
                              style={statusStyle}
                            >
                              {status.status_name}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                  <div className="md:grid hidden grid-cols-12">
                    {statusList.map((status) => {
                      const salesStatus = parseInt(salesData.status);
                      let statusStyle;

                      if (status.id_transaction_status < salesStatus) {
                        statusStyle = passStyle;
                      } else if (status.id_transaction_status == salesStatus) {
                        if(status.id_transaction_status == 1 || status.id_transaction_status == 6){
                          statusStyle = cancelStyle;
                        }else{
                          statusStyle = onGoingStyle;
                        }
                      } else {
                        statusStyle = becomeStyle;
                      }

                      return (
                        <div
                          className="xl:col-span-2 col-span-4 text-xs font-medium mr-2 px-3 py-1 xl:mb-0 mb-3"
                          style={statusStyle}
                        >
                          {status.status_name}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div>
              {salesData.detail.map((detail) => {
                return (
                  <Link to={'/product/' + detail.catalog_id}>
                    <div className="grid grid-cols-6 items-end hover:bg-gray-100 md:px-0 px-2 mb-5" style={{ gridAutoRows:'auto' }}>
                      <div className="md:col-span-5 col-span-6">
                        <div className="grid grid-cols-6 justify-between md:flex-row w-full relative">
                          <div className="md:col-span-1 col-span-2 overflow-hidden max-h-48 flex justify-center items-center">
                            <img
                              className="object-cover w-full"
                              src={process.env.REACT_APP_BACKEND_WEB + "/" + detail.thumbnail}
                              alt={detail.catalog_name}
                            />
                          </div>
                          <div className="md:col-span-5 col-span-4">
                            <div className="flex flex-col justify-between px-4 leading-normal md:w-3/4 w-full">
                              <div>
                                <h5 className="mb-3 md:text-xl text-md font-semibold tracking-tight text-gray-900">
                                  {detail.catalog_name}
                                </h5>
                                <p className="mb-4 font-normal text-gray-400 md:text-md text-sm">
                                  {
                                    (detail.size != null) ? 
                                    <>
                                      Ukuran : {detail.size}
                                      <br />
                                    </> : ''
                                  }
                                  Jumlah : {detail.qty}
                                  <br />
                                  Varian : {detail.product_name  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="md:col-span-1 md:h-full col-span-6  ">
                        <div className="flex items-center justify-end h-full">
                          <h5 className="mb-2 font-semibold md:text-lg text-md text-gray-700 text-right">
                            {formatToIDR(detail.sub_total)}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
            <div className="border-t border-gray-400 pt-4">
              <div className="grid grid-cols-5" style={{ gridAutoRows:'auto' }}>
                <div className="col-span-3 md:block hidden">
                  <div className="h-full flex items-end">
                    <p className="text-md">
                      Barang disewakan dengan durasi normal 3 x 24 jam proses sewa{" "}
                    </p>
                  </div>
                </div>
                <div className="md:col-span-2 col-span-5">
                  {/* <div className="flex justify-between mb-0.5">
                    <h5 className="font-semibold text-lg">SubTotal Produk</h5>
                    <h5 className="font-semibold text-lg">
                      Rp {salesData.sub_total}
                    </h5>
                  </div> */}
                  {/* <div className="flex justify-between mb-0.5">
                    <h5 className="font-semibold text-lg">Biaya Pengiriman</h5>
                    <h5 className="font-semibold text-lg">
                      Rp {salesData.delivery_fee}
                    </h5>
                  </div>
                  <div className="flex justify-between mb-2">
                    <h5 className="font-semibold text-lg">Biaya Layanan</h5>
                    <h5 className="font-semibold text-lg">
                      Rp {sales.service_fee}
                    </h5>
                  </div> */}
                  <div className="flex justify-end items-center">
                    <h5 className="font-semibold md:text-xl text-md">
                      Total : {formatToIDR(salesData.grand_total)}
                    </h5>
                    {
                      (salesData!=null && salesData.is_dp == 0) ? (salesData.status > 2 && paidFullPayment == false) ?  
                      <Link to={'/payment?sales=' + salesData.sales_id}> <button className="bg-cyan-600 hover:bg-cyan-700 text-white py-2 px-3 ml-3">Bayar Pesanan</button> </Link>: '' : ''
                    }
                   
                  </div>
                  {
                    (salesData.is_dp == 1) ?
                    <div className="flex justify-end items-center mt-2">
                      <h5 className="font-semibold md:text-xl text-md">
                        DP : {formatToIDR(salesData.dp_value)} {(paidDownPayment == true) ? '(Lunas)' : ''}
                      </h5>
                      {
                        (salesData!=null) ? (salesData.status > 2 && (paidFullPayment == false || paidDownPayment == false)) ?  
                        <Link to={'/payment?sales=' + salesData.sales_id}> <button className="bg-cyan-600 hover:bg-cyan-700 text-white py-2 px-3 ml-3">
                          {(salesData.is_dp == 1 && paidDownPayment == false) ? 'Bayar DP' : 'Bayar Penuh'}
                        </button> </Link>: '' : ''
                      }
                    </div>
                      : ''
                  }
                  {
                    (salesData.status > 2 && salesData.is_dp == 1  && paidDownPayment == false) ?
                    <div className="text-orange-700 text-right font-medium mt-1"><small>* Pembayaran DP Belum Terverifikasi</small></div> : ''
                  }
                  {
                    ( salesData.status > 2 && paidFullPayment == false) ?
                    <div className="text-orange-700 text-right font-medium mt-1"><small>* Pembayaran Penuh Belum Terverifikasi</small></div> : ''
                  }
                </div>
                <div className="md:col-span-3 col-span-5 mt-3 md:hidden block">
                  <div className="h-full flex items-end">
                    <p className="md:text-md text-xs">
                      Barang disewakan dengan durasi normal 3 x 24 jam proses sewa{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* {detailInfo} */}
        </> : ''
      }
      </div>
    </>
  );
}
