import 'assets/css/app.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HomePage from 'pages/HomePage';
import Details from 'pages/Details';
import Congratulations from 'pages/Congratulations';
import NotFound from 'pages/NotFound';
import Products from 'pages/Products';
import ProductDetail from 'pages/ProductDetail';
import Cart from 'pages/Cart';
import Payment from 'pages/Payment';
import TransactionDetail from 'pages/TransactionDetail';
import Transactions from 'pages/Transactions';
import Profile from 'pages/Profile';
import ForgetPassword from 'pages/ForgetPassword';
import Terms from 'pages/Terms';
import Notification from 'pages/Notification';
import { Icon } from '@iconify/react';
import Logout from 'helpers/Logout';
import useAsync from 'helpers/hooks/useAsync';
import fetchData from 'helpers/fetchApi';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

function App() {
  const companyRequest = useAsync();
  const [cookies, setCookie, removeCookie] = useCookies()
  const [profile, setProfile] = useState(null);
  
  const getCompanyProfile = (token) => {
    companyRequest.run(fetchData({url: '/company-profile/1', token: token}))
  }

  const storeCompanyProfile = (data) => {
    setProfile(null);
    if(data != undefined && data != null){
      if(data.status == true){
        setProfile(data.data[0]);
      }
    }
  }

  useEffect(() => {
    getCompanyProfile(cookies.token);
  }, [cookies])

  useEffect(() => {
    storeCompanyProfile(companyRequest.data)
  }, [companyRequest.data])

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path='/' Component={HomePage}/>
          <Route path='/categories/:idc' Component={Details}/>
          <Route path='/products' Component={Products}/>
          <Route path='/product/:idc' Component={ProductDetail}/>
          <Route path='/cart' Component={Cart}/>
          <Route path='/payment' Component={Payment}/>
          <Route path='/congratulations' Component={Congratulations}/>
          <Route path='/profile/' Component={Profile}/>
          <Route path='/transaction/' Component={Transactions}/>
          <Route path='/transaction/detail/:idc' Component={TransactionDetail}/>
          <Route path='/forget_password/' Component={ForgetPassword}/>
          <Route path='/terms/' Component={Terms}/>
          <Route path='/notification/' Component={Notification}/>
          <Route path='/logout/' Component={Logout}/>
          <Route path='*'Component={NotFound}/>
        </Routes>
      </Router>
      <a href={'https://wa.me/' + profile?.phone} target='_blank' className='fixed bottom-16 right-6 md:right-12 z-20 md:block hidden'>
        <Icon icon="logos:whatsapp-icon" width={50}/>
      </a>
    </div>
  );
}

export default App;
