import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import Datepicker from "tailwind-datepicker-react"
import moment from "moment/moment";
import { Input } from "@material-tailwind/react";


const proceedPayment = (paymentMethod, virtualAccount) => {
  return (
    <>
      <div className="mb-5">
        <h3 className="px-4 py-2 text-lg font-medium text-gray-900 border border-gray-500">
          No Rekening
        </h3>
        <ul className="border border-gray-500">
          <li>
            <input
              type="text"
              id="va"
              name="va"
              value={virtualAccount}
              className="w-full border-none"
              readOnly
              required
            />
          </li>
        </ul>
      </div>
      <div>
        <h3 className="px-4 py-2 text-lg font-medium text-gray-900 border border-gray-500">
          Jenis Pembayaran
        </h3>
        <ul className="border border-gray-500">
          <li>
            <input
              type="text"
              id="choosedPayment"
              name="payment"
              value={paymentMethod}
              className="w-full border-none"
              readOnly
              required
            />
          </li>
        </ul>
      </div>
    </>
  );
};

const userInfo = {
  name: "Ardi",
  phone: "08324923744",
  address: "Jl. Soeta no 03, Surabaya, Jawa Timur ",
};

const nullParams = [null, "", "null", undefined];

const formatDate = (value) => {
    const date = new Date(value);
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: 'numeric' });
    return day + ' ' + month + ' ' + year;
}


function formatDateStrip(d) {
    if(d != null){
      var month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
    }
}

const today = moment().format('YYYY-MM-DD'); 

export default function PaymentDetail({isPay, address, name, phone, getDate, getPaymentType, salesData, eventDate = null, eventTime = null, setImage}) {

  const [show, setShow] = useState()
	const [selectedFormatDate, setSelectedFormatDate] = useState(eventDate)
	const [selectedDate, setSelectedDate] = useState(eventDate)
  const [selectedTime, setSelectedTime] = useState(eventTime)
	const handleChange = (selectedDate = null) => {
    if(selectedDate != null){
      setSelectedDate(selectedDate)
    //   setSelectedFormatDate(formatDate(selectedDate))
    }
	}

  const handleChangeTime = (value) => {
    setSelectedTime(value);
  }

  const handleChangeDateTime = (date = null, time = null) => {
    let dateTime;

    if(date != null && time != null){
      dateTime = date + " " + time + ":00";
    }else{
      dateTime = null;
    }

    console.log(dateTime)
  
    if(typeof getDate == 'function'){
      getDate(dateTime);
    }
  }
	const handleClose = (state) => {
		setShow(state)
	}

  useEffect(() => {
    handleChangeDateTime(selectedDate, selectedTime);
  }, [selectedDate, selectedTime])
  

  const options = {
    title: "Pick Rental Date",
    autoHide: true,
    todayBtn: true,
    clearBtn: true,
    clearBtnText: "Clear",
    maxDate: "",
    minDate: today,
    theme: {
      background: "",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "bg-gray-200",
      input: "",
      inputIcon: "",
      selected: "hover:text-gray-800",
    },
    datepickerClassNames: "top-12",
    language: "id",
    disabledDates: [],
    weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Select Date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric"
    }
  }

  const handleChangeImage = (e) => {
    if(typeof setImage == 'function'){
      setImage(e.target.files[0])
    }
  }

  const [paymentPhotoModal, setPaymentPhotoModal] = useState({
    open: false,
    path: null,
    text: 'Preview Payment' 
  })

  const previewPaymentModal =
      <>
        <div id="small-modal" tabindex="-1" class="fixed top-0 left-0 right-0 bottom-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 max-h-full bg-gray-800/50">
          <div class="relative w-full max-w-md max-h-full mx-auto mt-10">
              <div class="relative bg-white rounded-lg shadow">
                  <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                      <h3 class="text-xl font-medium text-gray-900">
                          {paymentPhotoModal?.text  }
                      </h3>
                      <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="small-modal" onClick={(e) => handleOpenPhotoModal(null)}>
                          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                          </svg>
                          <span class="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div class="p-4 md:p-5 space-y-4">
                    <img
                        className="w-full"
                        src={process.env.REACT_APP_BACKEND_WEB + "/" + paymentPhotoModal?.path}
                      />
                  </div>
                  <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b justify-center">
                      <button data-modal-hide="small-modal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center" onClick={(e) => handleOpenPhotoModal(null)}>Close</button>
                  </div>
              </div>
          </div>
      </div>
      </>

  const handleOpenPhotoModal = (photoPath = null) => {
    console.log('open preview')
    if(paymentPhotoModal?.open == false){
      setPaymentPhotoModal({
        open: true,
        path: photoPath,
        text: 'Preview Payment' 
      })
    }else{
      setPaymentPhotoModal({
        open: false,
        path: null,
        text: 'Preview Payment'  
      })
    }
  }


  useEffect(() => {
    console.log('preview');
    console.log(paymentPhotoModal);
  }, [paymentPhotoModal])

  const uploadDoc = <>
    <div className="mb-8">
      <h5 className="font-semibold text-gray-900 mb-3">Upload Bukti Pembayaran</h5>
      <div className="mb-4">
        {
          salesData?.payment?.map((item) => {
            return(
              <button className="bg-gray-800 text-white px-3 py-2 mr-4" onClick={(e) => handleOpenPhotoModal(item.path_photo)}>
                {(item.payment_type == 0 || item.payment_type == null) ? 'Preview DP' : 'Preview Pelunasan'}
              </button>
            )
          })
        }
      </div>
      <div className="">
        <input class="block w-1/2 text-sm text-gray-900 cursor-pointer" onChange={handleChangeImage} id="file_input" type="file"  placeholder="File Belum Dipilih" title="File Belum Dipilih"/>
      </div>
    </div>
  </>

  const handleChangePaymentType = (e) => {
    let paymentType = null;
    const types = document.getElementsByName("paymentType");
    
    for (var i = 0, length = types.length; i < length; i++) {
      if (types[i].checked) {
        paymentType = types[i].value;
        break;
      }
    }

    getPaymentType(paymentType);
  }

  const choosePayment = (
    <>
      <div>
        <h3 className="px-4 py-2 text-lg font-medium text-gray-900 border border-gray-500">
          Jenis Pembayaran
        </h3>
        <ul className="border border-gray-500">
          <li>
            <input
              type="radio"
              id="lunas"
              name="paymentType"
              value="1"
              className="hidden peer"
              onClick={(e) => handleChangePaymentType(e)}
              required
            />
            <label
              for="lunas"
              className="inline-flex items-center justify-between w-full px-4 py-2     text-gray-500 peer-checked:bg-gray-200 peer-checked:text-gray-700 hover:text-gray-600 hover:bg-gray-100 cursor-pointer "
            >
              Lunas
            </label>
          </li>
          <li>
            <input
              type="radio"
              id="dp"
              name="paymentType"
              value="0"
              onClick={(e) => handleChangePaymentType(e)}
              className="hidden peer"
              required
            />
            <label
              for="dp"
              className="inline-flex items-center justify-between w-full px-4 py-2     text-gray-500 peer-checked:bg-gray-200 peer-checked:text-gray-700 hover:text-gray-600 hover:bg-gray-100 cursor-pointer "
            >
              DP 50%
            </label>
          </li>
        </ul>
      </div>
    </>
  );

  console.log(eventDate);

  return (
    <>
      <div className="mb-8">
        {isPay == true
          ? proceedPayment((salesData == null) ? "Lunas" : (salesData.is_dp == 1) ? "DP 50%" : "Lunas", "01293810984732879 ")
          : choosePayment}
      </div>
      <div className="mb-8">
        <h5 className="font-semibold text-gray-900 mb-3">Tanggal Sewa <span className="text-sm font-medium text-gray-600">*sewa untuk 3 hari</span></h5>
        <div className="md:w-3/4 w-full border border-gray-500 flex justify-between items-center relative">
          {
            (eventDate != null && isPay == true) ?
            <div className="relative w-full">
              <input type="text" className="w-full" placeholder="Select Date" value={formatDate(eventDate)} readOnly />
              <label className="absolute top-1/2 right-2 -mt-2 cursor-pointer" for="event_date">
                <Icon icon="uiw:date"/>
              </label>
            </div>
            : 
            <>
              <input type="date" name="date_event" className="w-full border border-gray-800" min={today} onChange={(e) => handleChange(e.target.value)}/>
              {/* <Datepicker options={options} className="w-full rounded-none" onChange={handleChange} show={show} setShow={handleClose}>
                <div className="relative">
                  <input type="text" className="w-full" placeholder="Select Date" value={selectedFormatDate} onFocus={() => setShow(true)} readOnly />
                  <label className="absolute top-1/2 right-2 -mt-2 cursor-pointer" for="event_date">
                    <Icon icon="uiw:date"/>
                  </label>
                </div>
              </Datepicker> */}
            </>
          }
          <div>
            <input type="time" className="border border-gray-800" id="time" value={(eventDate != null && isPay == true) ? eventDate.split(" ")[1] : null} readOnly={(eventDate != null && isPay == true) ? true : false} onChange={(e) => handleChangeTime(e.target.value)}/>
          </div>
        </div>
      </div>
      <div className="mb-8">
        <h5 className="font-semibold text-gray-900 mb-3">Alamat</h5>
        <div className="bg-gray-100 py-3 px-4 mb-3">
          {(!nullParams.includes(name)) ? name : ''} {(!nullParams.includes(phone)) ? "- " + phone : ''}
        </div>
        <div className="bg-gray-100 py-3 px-4 mb-3">{address}</div>
      </div>
      {
        (isPay == true) ? 
        uploadDoc : ''
      }
      
      {
        (paymentPhotoModal?.open == true) ?
        previewPaymentModal : ''
      }
    </>
  );
}
